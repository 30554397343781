.navbar-default{
    background-color: rgba(255, 255, 255, 1) !important;
    height: 60px;
    font-size: 16px;
    vertical-align: middle;
    .nav > li {
    margin: 5px;
  }
}

.navbar-logo{
   max-width: 130px;
   margin-top: -10px;
}
