.presentation-img{
  max-width: 300px !important;
}

.presentation-logo-2{
  background-color: #FFF;
  border-left-width: 10px;
  border-left-style: solid;
  border-color: #009900;
  padding:10px;
  float: right;
  max-width: 300px !important;
  max-height: 300px;
height: auto;
width: auto;
}
.presentation-logo{
  background-color: #FFF;
  border-left-width: 10px;
  border-left-style: solid;
  border-color: #009900;
  padding:10px;
  max-width: 150px !important;
  float: left !important;
}

@media (min-width: $screen-lg-min){
  .presentation-logo .presentation-logo-2{
    max-width: 210px !important;
    padding-left: 20px;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-lg-min){
  .presentation-logo .presentation-logo-2{
    //box-shadow: none !important;
    max-width: 210px !important;
  //  float: right;
    padding-left: 20px;
  }
}

@media (max-width: $screen-xs-max){
  .presentation-logo{
    //float: right;
    max-width: 170px !important;
    padding-left: 10px;
  }
}

.button-presentation{
  margin-bottom: 100px !important;
  margin-top: 20px;
  //  border: 3px solid darken($main-color,15%);
  width: 200px;
  font-size: 20px;
  display:block;
  margin-left:auto;
  margin-right:auto;
  //  background: #009900;
  background: rgba(255,255,255,0.7);
  color: darken($second-bg-color,75%);
  text-shadow: 0 0 5px #FFF; /* horizontal-offset vertical-offset 'blur' colour */
  -moz-text-shadow: 0 0 5px #FFF;
  -webkit-text-shadow: 0 0 5px #FFF;
  font-weight: bold !important;
  transition: transform .25s ease !important;
  &:hover{
    //  background: rgba(255,255,255,0.3);
    color: darken($second-bg-color,75%) !important;
    transform: scale(1.1,1.1);
    transition: all .30s ease !important;
  }
}

/********************************/
/*       Fade Bs-carousel       */
/********************************/
.fade-carousel {
  position: relative;
//  height: 100vh;
}
.fade-carousel .carousel-inner .item {
  height: 100vh;
  overflow: hidden;
}
.fade-carousel .carousel-indicators > li {
  margin: 0 2px;
  background-color: rgba(255,255,255,0.5);
  border-color: #FFF;
  opacity: 0.7;
}
.fade-carousel .carousel-indicators > li.active {
  width: 10px;
  height: 10px;
  background-color: rgba(255,255,255,0.9);


}

/********************************/
/*          Hero Headers        */
/********************************/
.hero {

  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  font: 400 3em/1.3 'Kalam', Helvetica, sans-serif;
  height: 100%;
  padding-top: 180px;
}

.slide-bg{
  background-color: rgba($second-bg-color,0.5);
}

@media(max-width: $screen-lg-min){
  .slider-brand{
    height: 140px !important;
    max-width: 270px !important;
  }
}
@media (min-width: $screen-md-min){
  .slide-bg{
    background-color: transparent;
  }
  #container:before {
    content: '';
    position: absolute;
    left: 60%;
    width: 100%;
    height: 200%;
    background-color: rgb(255, 255, 255); /* fallback */
    background-color: rgba(255, 255, 255, 0.6);
    top: 0;
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  #container2:before {
    content: '';
    position: absolute;
    left: -60%;
    width: 100%;
    height: 200%;
    background-color: rgb(255, 255, 255); /* fallback */
    background-color: rgba(255, 255, 255, 0.6);
    top: 0;
    -webkit-transform: rotate(-20deg);
    -moz-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }

}

.hero h3 {
  font-size: 28px;
  font-weight: bold;
  //  font: 400 27px 'Kalam', Helvetica, sans-serif;
  color: darken($second-bg-color,72%) !important;
  text-shadow: 0 0 5px #FFF; /* horizontal-offset vertical-offset 'blur' colour */
  -moz-text-shadow: 0 0 5px #FFF;
  -webkit-text-shadow: 0 0 5px #FFF;

}

.hero p {
  font-size: 14px;
  font-weight: bold;
  //  font: 400 27px 'Kalam', Helvetica, sans-serif;
  color: darken($second-bg-color,72%) !important;
  text-shadow: 0 0 5px #FFF; /* horizontal-offset vertical-offset 'blur' colour */
  -moz-text-shadow: 0 0 5px #FFF;
  -webkit-text-shadow: 0 0 5px #FFF;

}

.fade-carousel .carousel-inner .item .hero {
  opacity: 0;
  -webkit-transition: 2s all ease-in-out .1s;
  -moz-transition: 2s all ease-in-out .1s;
  -ms-transition: 2s all ease-in-out .1s;
  -o-transition: 2s all ease-in-out .1s;
  transition: 2s all ease-in-out .1s;
}
.fade-carousel .carousel-inner .item.active .hero {
  opacity: 1;
  -webkit-transition: 2s all ease-in-out .1s;
  -moz-transition: 2s all ease-in-out .1s;
  -ms-transition: 2s all ease-in-out .1s;
  -o-transition: 2s all ease-in-out .1s;
  transition: 2s all ease-in-out .1s;
}

/********************************/
/*          Custom Buttons      */
/********************************/
.btn.btn-lg {padding: 10px 40px;}
.btn.btn-hero,
.btn.btn-hero:hover,
.btn.btn-hero:focus {
  color: #FFFFFF;
  background-color: $main-color;
  border-color: $main-color;
  outline: none;
  margin: 20px auto;
}

/********************************/
/*       Slides backgrounds     */
/********************************/
.fade-carousel .slides .slide-1,
.fade-carousel .slides .slide-2,
.fade-carousel .slides .slide-3
.fade-carousel .slides .slide-4 {
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

}
.fade-carousel .slides .slide-1 {
  background-image: url('../../images/slider1.jpg');
}


.slider-brand{
  height: 140px;
//  margin-top: 30px;
  margin-bottom: 30px;
  -webkit-filter: drop-shadow(1px 1px 0 white)
  drop-shadow(-1px 1px 0 white)
  drop-shadow(1px -1px 0 white)
  drop-shadow(-1px -1px 0 white);

  filter: drop-shadow(1px 1px 0 white)
  drop-shadow(-1px 1px 0 white)
  drop-shadow(1px -1px 0 white)
  drop-shadow(-1px -1px 0 white);
}
