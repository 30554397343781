.line-panel{
  background-color: #FFF;
  margin-bottom: 30px;
  text-align: center;
  padding-bottom: 5px;
  margin-bottom: 25px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  -webkit-transform: scale(1); //translate(0px);
   -webkit-transition: all 0.25s ease;
   border-radius: 10px;
overflow: hidden;
height: 240px;
  &:hover{
      -webkit-transform: scale(1.06);
     -webkit-transition: all 0.25s ease;
    }
  a{
    color: darken($secondary-color,15%);
    font-size: 20px;
  }
}


.line-preview-img{
   box-shadow: inset 0 0 50px grey;

   img{
     object-fit: cover;
     width: auto;
     height: auto;
      overflow: hidden;
      max-height: 150px;
      max-width: 200px;
     display: block;
     position: relative;
     }
}

.line-preview-title{
  background-color: rgba(255,255,255,0.9);
  color: $secondary-color;
  font-size: 15px;
  font-weight: bold;
  margin-top: 10px;
  padding-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
}
.line-link{
  text-decoration: none;
  &:hover{
      text-decoration: none;
  }
  &:active{
      text-decoration: none;
  }
}

@media (min-width: $screen-md-min) {
  .masonry-lines {
      column-count: 4;
      -webkit-column-count: 4;
      column-gap: 0.5em;
  }
}
@media (min-width: $screen-sm-min) and (max-width: $screen-md-min){
  .masonry-lines {
      column-count: 3;
      -webkit-column-count: 3;
      column-gap: 0.5em;
  }
}
@media (max-width: $screen-xs-max) {
  .masonry-lines {
      column-count: 1;
      -webkit-column-count: 1;
      column-gap: 1em;
  }

}

.item { /* Masonry bricks or child elements */
    //background-color: #eee;
    display: inline-block;
    margin: 0 0 1em;
    width: 100%;
}
