.card {
  margin-top: 20px;
  padding: 30px;
  background-color: rgba(214, 224, 226, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.card.hovercard {
  position: relative;
  padding-top: 0;
  overflow: hidden;
  //  text-align: center;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 1);
}
.card.hovercard .card-background {
  height: 130px;
}

.card.hovercard .card-info {
  position: absolute;
  bottom: 14px;
  left: 0;
  right: 0;
}
.card.hovercard .card-info .card-title {
  padding:0 20px;
  font-size: 35px;
  line-height: 3;
  color: #FFF !important;
  background-color: rgba(000, 000, 000, 0.3);

  padding-left: 2%;
  padding-right: 3%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.card.hovercard .card-info {
  overflow: hidden;
  font-size: 12px;
  line-height: 20px;
  color: #737373;
  text-overflow: ellipsis;
}
.card.hovercard .bottom {
  padding: 0 20px;
  margin-bottom: 17px;
}
.btn-pref .btn {
  -webkit-border-radius:0 !important;
}

.btn-pref{
  &:focus,&:active{
    outline-color: transparent;
  }
}


.tab-title{
  padding-top: 17px !important;
  padding-bottom: 17px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tab-green{
  background-color: $main-color;
  color: #FFF !important;
  font-size: 17px !important;
}
.tab-dark-brown{
  background-color: #B4045F;
  color: #FFF !important;
  font-size: 17px !important;
}
.tab-light-brown{
  background-color: #FE9A2E;
  color: #FFF !important;
  font-size: 17px !important;
}
.tab-green{
  background-color: #3C7030;
  color: #FFF !important;
  font-size: 17px !important;
}

.tab-img{
  margin-bottom: 30px;
  -webkit-box-shadow: 10px 10px 29px -10px rgba(0,153,0,0.38);
  -moz-box-shadow: 10px 10px 29px -10px rgba(0,153,0,0.38);
  box-shadow: 10px 10px 29px -10px rgba(0,153,0,0.38);
  //-webkit-box-shadow: 10px 10px 28px 0px rgba(150,147,150,1);
  //-moz-box-shadow: 10px 10px 28px 0px rgba(150,147,150,1);
  //box-shadow: 10px 10px 28px 0px rgba(150,147,150,1);
}
.right{
  float: right;
  margin-left: 40px;
  margin-right: 0px;
}
.left{
  float: left;
  margin-left: 0px;
  margin-right: 40px;
}

@media (min-width: $screen-sm-min){
.max-width-img{
  max-width: 350px;
}
}
@media (min-width: $screen-xs-max) and (max-width: $screen-sm-min){
  .max-width-img{
    max-width: 250px;
  }
}

.max-width-img-historia{
  max-width: 220px;
}
.max-width-img-instalaciones{
    max-width: 190px;
    margin-top: 15px;
}



.tab-h2{
  color: darken($main-color,5%);
  font-weight: bold;
  font-size: 22px;
}

@media (min-width: $screen-sm-min) {

  .tab-effect {
    letter-spacing: 0;
    transition: all 480ms ease-in-out;
  }

  .tab-effect:hover,
  .tab-effect:active {
    letter-spacing: 5px;
    transition: all 480ms ease-in-out;

  }

/*  .tab-effect:after,
  .tab-effect:before {
    backface-visibility: hidden;
    border: 1px solid rgba(#fff, 0);
    bottom: 0px;
    content: " ";
    display: block;
    margin: 0 auto;
    position: relative;
    transition: all 480ms ease-in-out;
    width: 0;
  }

  .tab-effect:hover:after,
  .tab-effect:hover:before {
    backface-visibility: hidden;
    border-color: #fff;
    transition: width 480ms ease-in-out;
    width: 70%;
  }*/


  .tab-effect:hover{
    &:after{
      letter-spacing: 0px !important;
      transition: width 480ms ease-in-out !important
    }
  }

  .tab-effect:hover:before {
    bottom: auto;
    top: 0;
    width: 70%;
  }

  .tab-effect:focus{
    //background-color: lighten($main-color, 5%) !important;

  }
}

.brown-text{
  color: #926637;
}
.light-brown-text{
  color: #d49b50;
}
.green-text{
  color: #6f994e;
}


.btn-active{
//  background-color: lighten($main-color, 5%) !important;
  &:focus,&:active{
    outline-color: transparent;
  }
}

@media (max-width: $screen-xs-max) {
  .tab-default{
    font-size:14px !important;
  }
  .tab-effect:focus{
    //background-color: lighten($main-color, 5%) !important;
  }
}
.well{
  border: none !important;
  background-color: #FFF !important;
}
.texto{
  text-indent: 30px;
  text-align: justify;
  font-size: 16px;
  padding-bottom: 30px;
  padding-left: 0px;
  padding-right: 0px;
  color: $text-color;
  font-weight: 600;
}
