.line-container{
    margin-bottom: 40px;
    overflow: hidden !important;
    height: 250px;
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    transition: scale(1);
    transition: all 0.25s ease;
    .post-preview-img{
       box-shadow: none !important;
      height: 250px;
    }


    img{
      object-fit: cover;
      width: 100%;
      overflow: hidden;
      height: 250px;
      display: block;
      position: relative;


    }

      &:hover{
        .brand-panel{
          transform: scale(1.05);
          transition: all 0.25s ease;
        }
        transform: scale(1.05) translateY(6px);
        transition: all 0.25s ease;
      }
}

.brand{
  max-width: 75px;
  padding: 0%;
  text-align: center;
  display: inline-block !important;
  margin-bottom: 15px;
  margin-top: 22px;
  margin-right: 15px;
  margin-left: -37px;
  max-height: 57px;
}


 .brand-panel{
     -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
     box-sizing: content-box;
     position: absolute;
     z-index: 1;
     width: 0;
     height:90px;
     margin: 0 0 0 35px;
     border: 50px solid rgba(255,255,255,0.95);
     border-top: 0 solid;
     border-bottom: 20px solid rgba(0,0,0,0);
     font: normal 100%/normal Arial, Helvetica, sans-serif;
     color: rgba(0,0,0,1);
     -o-text-overflow: clip;
     text-overflow: clip;
     background: rgba(0,0,0,0);
     display:table-cell;
     vertical-align:middle;
     text-align:center;


 }

.nuestros-prod{
  width: 100%;
padding-right: 10%;
padding-left: 10%;
}

.padding-remover{
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.line-title{
  a{
    color: $brand-brown;
  }
  text-align: center;;
  color: darken($main-color,15%) !important;
  font: 500 18px/1.3 'Kalam', Helvetica, sans-serif;
  background-color: rgba(255, 255, 255, 0.7);
  padding-top:10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 63px;
  position: relative;
  margin-top: -70px;
  letter-spacing: 0px;
  transition: all 0.25s ease;
  max-width: 100%;
  word-wrap: break-word;
}
