.container{
  width: 100% !important;
}
.carousel{
  display: block;
  max-width: 100%;
}


@media all and (orientation:landscape) {
    @media (min-width: $screen-md-max){
      .carousel-inner{
        height: 600px !important;
        .hero img{
          max-height: 100px !important;
          margin-top: 30px !important;
        }
        #container:before {

          -webkit-transform: rotate(13deg);
          -moz-transform: rotate(13deg);
          transform: rotate(13deg);
        }
        #container2:before {

          -webkit-transform: rotate(-13deg);
          -moz-transform: rotate(-13deg);
          transform: rotate(-13deg);
        }

      }
    }
    @media (max-width: $screen-xs-max){
      .carousel-inner{
        height: 320px !important;
        .hero img{
          max-height: 70px !important;
          margin-top: -130px !important;
        }
        .hero h3{
          font-size: 18px !important;
          margin-top: -50px !important;
        }
        .button-presentation{
          font-size: 14px !important;
          margin-top: 0px !important;
        }
      }
    }
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
      .carousel-inner{
        height: 650px !important;
        .hero img{
          max-width: 280px !important;
          height: auto;
         margin-top: 110px !important;
        }
        .hero h3{
          font-size: 20px !important;
          margin-top: 0px !important;
        }
        #container:before {

          -webkit-transform: rotate(13deg);
          -moz-transform: rotate(13deg);
          transform: rotate(13deg);
        }
        #container2:before {

          -webkit-transform: rotate(-13deg);
          -moz-transform: rotate(-13deg);
          transform: rotate(-13deg);
        }
      }
    }
}
@media all and (orientation:portrait) {
  @media (min-width: $screen-md-max){
    .carousel-inner{
      height: 800px !important;
    }
    .hero img{
      max-width: 280px !important;
      margin-top: -20px !important;
    }
    .hero h3{
      font-size: 26px !important;
      margin-top: 30px !important;
    }
    .button-presentation{
      font-size: 22px !important;
      margin-top: 10px !important;
    }
  }
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
      .carousel-inner{
        height: 650px !important;
      }
      .hero img{
        min-height: 120px !important;
        margin-top: -20px !important;
      }
      .hero h3{
        font-size: 26px !important;
        margin-top: 30px !important;
      }
      .button-presentation{
        font-size: 22px !important;
        margin-top: 30px !important;
      }
    }
    @media (max-width: $screen-xs-max){
      .carousel-inner{
        height: 480px !important;
        .hero img{
          max-height: 100px !important;
          margin-top: -70px !important;
        }
        .hero h3{
          font-size: 20px !important;
          margin-top: 10px !important;
        }
        .button-presentation{
          font-size: 18px !important;
          margin-top: 10px !important;
        }
      }
    }
    @media (min-width: $screen-lg-min) {
      .carousel-inner{
        height: 800px !important;
      }
    }
}



.carousel-inner-presentations{
  height: 180px !important;
}
