
.table-links{

  font-size: 14px !important;

 a{
  color: darken($main-color,5%);

  &:hover{
    color: lighten($main-color, 5%);
  }
  &:focus{
    color: darken($main-color, 5%);
  }

}
}

.table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
    padding: 5px;
  }

.table-responsive {
    border-radius: 10px;
}

.thead-color{
  color: #FFF;
  background-color: #009900;
}

.table-striped-white{
  background-color: #FFF !important;
}
