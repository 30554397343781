.breadcrumb {
//    padding: 8px 15px;
//    margin-bottom: 22px;
//    list-style: none;
    background-color: transparent !important;
//    border-radius: 4px;
a{
  margin-left: -10px;
  color: darken($main-color,5%);
}
a:hover, a:focus {
    color: lighten($main-color,5%);
    text-decoration: underline;
  }
}
