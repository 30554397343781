.padding-top {
  padding-top: 30px;
}
.margin-top{
  margin-top: 30px;
}
.expanded{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.alargado{
  margin-right: 0px;
  margin-left: 0px;
  padding-left: 35px;
  padding-right: 35px;
}

.description-f-img{
  p{
    img{
      max-width: 100% !important;
      height: auto;
    }
  }
}

.fondo-titulos{
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
}

.text-center{
  text-align: center !important;
}
.titles-font{
  font: 400 25px/1.3 'Kalam', Helvetica, sans-serif;
  font-size: 30px;
  text-shadow: 4px 4px 3px rgba(0,99,0,0.4);
  font-weight: 500 !important;
  color: darken($main-color,5%) !important;
}
.posts-titles-font{
  //font: 400 25px/1.3 'Kalam', Helvetica, sans-serif;
  font-size: 19px;
  text-shadow: 4px 4px 3px rgba(0,99,0,0.4);
  font-weight: 500 !important;
  color: darken($main-color,5%) !important;
}



.section-titles-margin{
  margin-top: 50px;
}

.titles-font-section{
  font: 400 35px/1.3 'Kalam', Helvetica, sans-serif;
  text-shadow: 4px 4px 3px rgba(0,99,0,0.3);
  font-weight: 500;
  color: darken($main-color,5%) !important;
}

.boxed{
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.btn-custom{
  margin-bottom: 20px !important;
  margin-top: 10px;
  border: none;
  width: 100%;
  background-color: $main-color;
  color: #FFF;
  font-weight: bold !important;
  transition: transform .25s ease !important;
  &:hover{
    background-color: lighten($main-color,8%) !important;
    color: #FFF !important;
    transform: scale(1.05,1.2) rotateZ(-2deg);
    transition: all .30s ease !important;
  }
}

.btn-success{
  background-color: $main-color;
  color: #FFF;
  border: none;
  font-weight: bold !important;
  &:hover{
    background-color: lighten($main-color,8%) !important;
  }
}

.form-control:focus {
  border-color: #cccccc !important;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(111, 111, 111, 0.25);
}

h1{
  padding-bottom: 20px;
  color: darken($secondary-color,5%);

}

.google-maps {
  position: relative;
  padding-bottom: 75%; // This is the aspect ratio
  height: 0;
  overflow: hidden;
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.margin-r-15{
  margin-right: 15px;
}

.margin-l-15{
  margin-left: 15px;
}

.gray-bg{
  background-color: $second-bg-color;
}

.margin-10{
  margin: 10px;
}

.margin-t-10{
  margin-top: 10px;
}

.margin-bottom-0{
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.margin-top-150{
  margin-top: 150px;
}

.margin-top-40{
  margin-top: 40px;
}

.images-body{
  img{
    /*margin-bottom: 0px !important;
    margin-left: auto !important;
    overflow:auto !important;
    max-width: 100% !important;
    max-height: none !important;*/
    max-width: 100% !important;
    height: auto;
    }
}

.white-bg{
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}

.margin-top-30{
  margin-top: 30px;
}

.margin-b-30{
  margin-bottom: 30px;
}

@media (min-width: $screen-md-min){
  .margin-l-60{
    margin-left:125px;
  }
}
@media (max-width: $screen-md-min){
  .margin-l-60{
    margin-left: 60px;
  }
}

.padding-top-20{
  padding-top: 20px;
}
.padding-bottom-20{
  padding-bottom: 20px;
}

.padding-t-11{
  padding-top: 11px !important;
}

.slider{
  .slick-prev:before, .slick-next:before {
    color: #009900;
  }
}

.nav{
  background-color: rgba(255,255,255,1);
}
/*

$color1:$second-bg-color;

*, *:after, *::before {
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
}

.container {
overflow: hidden;
/* we don't want the pseudo-elements sticking out */
//}
/*
section {
position: relative;
padding: 1em ;
background: $color1;
color: #fff;
text-align: center;
}

section h2 {
margin: 0;
padding: 0;
font-size: 2.8em;
font-weight: 400;
}

section p {
color: lighten($color1, 65%);
font-size: 1.3em;
line-height: 1.5;
}

.col-2 .column {
width: 100%;
}

.column {
display: inline-block;
vertical-align: top;
}

.color {
background: darken($color1, 7%);
}

section::before,
section::after {
position: absolute;
content: '';
pointer-events: none;
}*/

/* Multiple Triangles
.ss-style-multitriangles::before,
.ss-style-multitriangles::after {
left: 50%;
width: 40px;
height: 40px;
-webkit-transform: translateX(-50%) rotate(45deg);
transform: translateX(-50%) rotate(45deg);
}

.ss-style-multitriangles::before {
top: -20px;
background: inherit;
box-shadow: -50px 50px 0 darken($color1, 7%), 50px -50px 0 darken($color1, 7%);
}

.ss-style-multitriangles::after {
bottom: -20px;
z-index: 10;
background: inherit;
box-shadow: -50px 50px 0 darken($color1, 7%), 50px -50px 0 darken($color1, 7%);
}



@media screen and (max-width: 54em) {
.column {
display: block;
width: 100% !important;
}

.col-2 .column:first-child .icon {
margin: 0 auto 50px;
}

.col-2 .column:nth-child(2) .icon {
margin: 50px auto 0;
}
}*/
