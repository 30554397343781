.social{
  text-align: center;
  img{
    margin-top: -10px;
    margin-left: 2px;
    margin-right: 2px;
    transition: transform .25s ease;
  }
  .titles-font{
    margin-bottom: -15px;
  }
}
.social img:hover{
  transform: scale(1.1);
  transition: transform .25s ease;

}

.share-text{
  font-size: 12px;
  font-weight: bold;
}

@media (min-width: $screen-md-min){
  .social{
    margin-top:30px;
    img{
      max-width: 40px;
    }
  }
}
@media (max-width: $screen-md-min){
  .social{
    margin-top:20px;
    img{
      max-width: 40px;
    }
  }
}
@media (max-width: $screen-xs-max){
  .social{
    margin-top:20px;
    img{
      max-width: 40px;
    }
  }
}
