.footer-section{
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.first{
  background-color: $second-bg-color;
  color: $secondary-text-color;

  img{
    padding-bottom: 30px;
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
}

@media (min-width: $screen-md-min){
  .img-res{
    margin-top: -80px !important;
    width: 40% !important;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-md-min){
  .img-res{
    margin-top: -60px !important;
    width: 60% !important;
  }
}

@media (max-width: $screen-xs-max){
  .img-res{
    margin-top: -50px !important;
    width: 80% !important;
  }
}

.img-responsive {
  display: inline-block !important;
}

.second{
  background-image: url('../../images/footer2.jpg');
  width: 100%;
  background-size: 100%;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
  height: 240px;
  display: block;
  align-items: center;
  background-repeat: no-repeat;
}


.bg-main-color{
  background-color: darken($main-color,5%) !important;
  color: #ffffff
}

.footer-bs {
  background-color: $second-bg-color;
  padding-top: 5px;
  color: $main-text-color;
  div{
    padding-top: 5px;
    background-color: $second-bg-color;
  }
  img{
    max-width: 125px;
    max-height: 70px;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 500px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.footer-bg-transparent{
  background-color: rgba(0,0,0,0.3) !important;
  padding: 10px;
  padding-bottom: 200px !important;
}

.no-border{
  border: none !important;
}

.footer-bs .footer-brand, .footer-bs .footer-nav, .footer-bs .footer-social, .footer-bs .footer-ns { padding:10px 25px; }
.footer-bs .footer-nav, .footer-bs .footer-social, .footer-bs .footer-ns { border-color: transparent; }
.footer-bs .footer-brand h2 { margin:0px 0px 10px; }
.footer-bs .footer-brand p { font-size:12px; color: $main-text-color; padding-top: 20px;}
.footer-bs .footer-brand li { font-size:12px; color: $main-text-color;}
.footer-bs .footer-nav ul.pages { list-style:none; padding:0px; }
.footer-bs .footer-nav ul.pages li { padding:5px 0px;}
.footer-bs .footer-nav ul.pages a { color: $main-text-color; font-weight:bold; }
.footer-bs .footer-nav ul.pages a:hover { color: $secondary-text-color; text-decoration:none; }
.footer-bs .footer-nav h4 {
  font-size: 14px;
  color: $main-color;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom:10px;
}

.padding-b-11{
  margin-bottom: 0px;
  padding-bottom: 11px;
}

.footer-bs .footer-nav ul.list { list-style:none; padding:0px; }
.footer-bs .footer-nav ul.list li { padding:5px 0px;}
.footer-bs .footer-nav ul.list a { color: $footer-text; }
.footer-bs .footer-nav ul.list a:hover { color: $secondary-text-color; text-decoration:none; }

.footer-bs .footer-social ul { list-style:none; padding:0px; }
.footer-bs .footer-social h4 {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.footer-bs .footer-social li { padding:5px 4px;}
.footer-bs .footer-social a { color: $footer-text;}
.footer-bs .footer-social a:hover { color: $secondary-text-color; text-decoration:none; }

.footer-bs .footer-ns h4 {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom:10px;
}
.footer-bs .footer-ns p { font-size:12px; color: $footer-text; }

@media (min-width: 768px) {
  .footer-bs .footer-nav, .footer-bs .footer-social, .footer-bs .footer-ns { border-left:solid 1px $main-text-color; margin-top: 20px;}
}

.comunidad{
  color: #fff;
  font-weight: bold;
  max-width: 200px;
}

.img-center{

  display: block;
    margin-left: auto;
    margin-right: auto;
}

.banco-alimentos-texto{
  color: #fff;
  font-weight: bold;
  max-width: 210px;
  text-align: center;

}


.logo-img{
  //max-width: 260px !important;
  max-height: 75px !important;
  margin-top: -10px;
}
.media-object{
  margin-top: 20px;
  object-fit: contain;
  max-height: 150px !important;
}

.datafiscal{
  max-height: 50px !important;
}

.mar-left-0{
  margin-left: 0px;
  margin-top: 0px !important;
//  padding-left: 20px !important;
}
.mar-right-0{
  margin-right: 0px;
  margin-top: 0px !important;
  padding-left: 20px !important;
}

.no-padding-l{
  padding-left: 0px;
}
