.post-preview-title{
  color: $secondary-color;
  font-size: 30px;
  font-weight: bold;
  margin-top: 20px;
  padding-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.post-preview-bg{
    background-color: $second-bg-color;
    color: $secondary-text-color;
}

.post-preview-text{
  text-align: justify;
  font-size: 15px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  color: $text-color;
  //font-weight: 500;
  text-indent: 15px;

}



.post-preview-img{

   box-shadow: inset 0 0 50px grey;

   img{
     object-fit: cover;
     width: 100%;
      overflow: hidden;
      height: 200px;
     display: block;
     position: relative;
     }
}

.post-panel{
  background-color: #FFF;
  display: block;
  //position: absolute;
  margin-bottom: 25px;
  margin-top: 15px;
  margin-right: 5px;
  margin-left: 5px;
  transform: scale(1);
  -ms-transform: scale(1); /* IE 9 */
  -webkit-transform: scale(1); /* Safari and Chrome */
  -o-transform: scale(1); /* Opera */
  -moz-transform: scale(1); /* Firefox */
 -webkit-transition: all 0.25s ease;
  &:hover{
     //-webkit-transform: scale(1.1);
     transform: scale(1.06);
     -ms-transform: scale(1.06); /* IE 9 */
     -webkit-transform: scale(1.06); /* Safari and Chrome */
     -o-transform: scale(1.06); /* Opera */
     -moz-transform: scale(1.06); /* Firefox */
      -webkit-transition: all 0.25s ease;
    }
}

.post-link{
  text-decoration: none;
  &:hover{
      text-decoration: none;
  }
  &:active{
      text-decoration: none;
  }
}

.shadow{
  -webkit-box-shadow: 10px 10px 29px -10px rgba(0,153,0,0.38);
  -moz-box-shadow: 10px 10px 29px -10px rgba(0,153,0,0.38);
  box-shadow: 10px 10px 29px -10px rgba(0,153,0,0.38);
  //-webkit-box-shadow: 10px 10px 18px 0px rgba(150,147,150,1);
  //-moz-box-shadow: 10px 10px 18px 0px rgba(150,147,150,1);
  //box-shadow: 10px 10px 18px 0px rgba(150,147,150,1);

}

@media (min-width: $screen-md-min) {
  .masonry { /* Masonry container */
      column-count: 3;
      column-gap: 1em;
  }
}
@media (min-width: $screen-sm-min) and (max-width: $screen-md-min){
  .masonry { /* Masonry container */
      column-count: 2;
      column-gap: 1em;
  }
}
@media (max-width: $screen-xs-max) {
  .masonry { /* Masonry container */
      column-count: 1;
      column-gap: 1em;
  }

}

.item { /* Masonry bricks or child elements */
    //background-color: #eee;
    display: inline-block;
    margin: 0 0 1em;
    width: 100%;
}
