

// Fonts

@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables

@import "variables";

// Bootstrap

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/slick-carousel/slick/slick-theme.scss";
@import "node_modules/font-awesome-sass/assets/stylesheets/font-awesome";

@import "global/global";
@import "components/navbar";
@import "components/container";
@import "components/image-panels";
@import "components/posts";
@import "components/tabs";
@import "components/footer";
@import "components/newsletter";
@import "components/social";
@import "components/last-posts";
@import "components/contact";
@import "components/line-details";
@import "components/presentation";
@import "components/lines";
@import "components/carousel";
@import "components/table";
@import "components/breadcrumbs";


// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
