.post{
  margin-top: 30px;
  margin-bottom: 60px;
.post-preview-title{
  padding-left: 0px;
}
img{
//  max-width: 420px;
//  float: right;
  background-color: #FFF;
  //max-width: 450px !important;
  -webkit-box-shadow: 10px 10px 29px -10px rgba(0,153,0,0.38);
  -moz-box-shadow: 10px 10px 29px -10px rgba(0,153,0,0.38);
  box-shadow: 10px 10px 29px -10px rgba(0,153,0,0.38);
//  -webkit-box-shadow: 10px 10px 28px 0px rgba(150,147,150,1);
  //-moz-box-shadow: 10px 10px 28px 0px rgba(150,147,150,1);
  //box-shadow: 10px 10px 28px 0px rgba(150,147,150,1);
  }

}

.product-img{

height: auto;
width: auto;
}

.slogan{
//  text-align: center;
  font: 400 30px/1.3 'Kalam', Helvetica, sans-serif;
  text-shadow: 4px 4px 3px rgba(0,99,0,0.4);
  font-weight: 500 !important;
}

@media (min-width: $screen-sm-min) and (max-width: $screen-md-min){
  .post{
    img{
      max-width: 350px;
    }
  }
}

@media (max-width: $screen-xs-max){
  .post{
    img{
      max-width: 220px;
    }
  }

}

.social-images{
  .row{
    margin-bottom: 5px;
  }
  .facebook{
    margin-left: 38px;
  }
  a{
    img{
      margin-bottom: 20px;
      max-width: 32px;
      box-shadow: none;
      margin-left: 5px;
      float:left;
      background-color: transparent;
      transition: transform .25s ease;
      &:hover{
        transform: scale(1.1);
        transition: transform .25s ease;
      }
    }
  }
  p{
    text-indent: 0px;
  }
}
