.newsletter{
  text-align: center;
  h2{
    font-weight: bold;
  }
}

.entries{
  color: darken($main-color,10%);
  font-size: 15px;
  font-weight: bold;
  margin-top: -15px;
  text-align: left;
  padding-right: 0px;
  padding-left: 0px;
  &:hover{
    text-decoration: none;
    color: $main-color;
  }
}
