.center-mode-img{
  max-width: 180px !important;
  float:left !important;
}
.slick-slider {
  margin-bottom: 20px;
}
.slick-slide img {
    display: block;
    height: 160px;
}
